@import url("https://fonts.googleapis.com/css2?family=Agbalumo&family=Amatic+SC&family=Concert+One&family=Josefin+Sans&family=Lobster&family=Merriweather:wght@300&family=Monomaniac+One&family=Oleo+Script&family=Open+Sans&family=Orbitron&family=Permanent+Marker&family=Pixelify+Sans&family=Titan+One&display=swap");
@import url(https://cdnjs.cloudflare.com/ajax/libs/normalize/8.0.1/normalize.min.css);

:root {
  --color-execute: #00e436;
  --color-number: #29adff;
  --color-vector2: #7073c3;
  --color-vector3: #f145bc;
  --color-vector4: #f76707;
  --color-color: #f76707;
  --color-string: #ae3ec9;
  --color-bool: #1d2b53;
  --color-gradient: #f59f00;
  --color-image: #ab5236;
  --color-material: #f7df07;
  --color-mesh: #7e2553;
  --color-struct: #ff004d;

  --color-selected: rgba(0, 0, 0, 0.3);

  --color-background: light-dark(#ffffff, #0d1117);
  --color-background-card: light-dark(#fcfcfc, #22262c);
  --color-background-button: light-dark(#ffffff, #151b23);
  --color-text: light-dark(#000000, #cad1d8);
  --color-text-minor: light-dark(#00000088, #cad1d888);
  --color-background-highghlight: light-dark(#eeeeee, #282828);
  --color-border: light-dark(#222, #dee4ea);
  --color-border-input: light-dark(#22222288, #dee4ea7e);
  --color-screen: light-dark(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.5)); /*The color of the screen behing a modal*/
  --color-selected: light-dark(rgba(0, 0, 0, 0.1), rgba(1, 1, 1, 0.3));
  --color-input: light-dark(#888, #888);
  --color-input-predefined: color-mix(in srgb, var(--color-input), transparent 90%);
  --color-slider-track: light-dark(#eeeeee, #282828);
  --color-slider-output: light-dark(#00000088, #cad1d888);

  --border-size: 0.2px;
  --card-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);

  --border-radius-large: 14px;
  --border-radius-medium: 12px;
  --border-radius-small: 8px;

  --padding-large: 14px;
  --padding-medium: 12px;
  --padding-small: 8px;
  --padding-tiny: 4px;

  --size-text: 14px;

  --gradient-transparent: repeating-conic-gradient(rgba(128, 128, 128, 0.2) 0% 25%, transparent 0% 50%) 50% / 20px 20px;
  @media (prefers-color-scheme: dark) {
    --card-shadow: none;
  }
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
html,
body,
#root {
  height: 100%;
  padding: 0;
  margin: 0;
  overflow: hidden;
  overscroll-behavior: none;
}

body {
  color-scheme: light dark;
}

.app {
  height: 100%;
  overflow: hidden;
  user-select: none;
  user-zoom: none;
  background: var(--color-background);
}

body .szh-menu {
  color: light-dark(#212529, #cad1d8);
  background-color: light-dark(#fff, #22262c);
  border: 1px solid light-dark(rgba(0, 0, 0, 0.1), #333);
  box-shadow: 0 2px 9px 3px rgba(0, 0, 0, 0.25);

  .szh-menu__arrow {
    background-color: light-dark(#fff, #22262c);
    border-left-color: light-dark(rgba(0, 0, 0, 0.1), #333);
    border-top-color: light-dark(rgba(0, 0, 0, 0.1), #333);
  }
}
body .szh-menu__item--hover {
  background-color: light-dark(#ebebeb, #31363c);
}
body .szh-menu__item--focusable {
  background-color: inherit;
}
body .szh-menu__item--disabled {
  color: light-dark(#aaa, #666);
}

body .szh-menu__divider {
  background-color: light-dark(rgba(0, 0, 0, 0.12), #3a3a3a);
}

.number,
.array-number {
  --color-property: var(--color-number);
}
.execute,
.array-execute {
  --color-property: var(--color-execute);
}
.vector,
.array-vector {
  --color-property: var(--color-vector2);
}
.vector2,
.array-vector2 {
  --color-property: var(--color-vector2);
}
.vector3,
.array-vector3 {
  --color-property: var(--color-vector3);
}
.vector4,
.array-vector4 {
  --color-property: var(--color-vector4);
}
.string,
.array-string {
  --color-property: var(--color-string);
}
.bool,
.array-bool {
  --color-property: var(--color-bool);
}
.color,
.array-color {
  --color-property: var(--color-color);
}
.gradient,
.array-gradient {
  --color-property: var(--color-gradient);
}
.image,
.array-image {
  --color-property: var(--color-image);
}
.material,
.array-material {
  --color-property: var(--color-material);
}
.mesh,
.array-mesh {
  --color-property: var(--color-mesh);
}
.struct,
.array-struct {
  --color-property: var(--color-struct);
}
iframe {
  display: none;
}
